import React from 'react'
import styled from 'styled-components/macro'
import { MapContainer, TileLayer, Marker, Popup } from 'react-leaflet'
import { LatLngExpression } from 'leaflet'

// Container style
const StyledMapContainer = styled(MapContainer)`
  width: 100%;
  height: 50vh;
  min-height: 300px;
`

// Map component
const MapSection: React.FC = () => {
  const center: LatLngExpression = [48.561031, -4.335477]
  const zoom = 17
  return (
    <StyledMapContainer
      center={center}
      zoom={zoom}
      scrollWheelZoom={false}
      dragging={false}
      attributionControl={false}
    >
      <TileLayer
        attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
      />
      <Marker position={center}>
        <Popup>Restaurant la Duchesse Anne</Popup>
      </Marker>
    </StyledMapContainer>
  )
}

export default MapSection
