import React from 'react'
import Container from 'react-bootstrap/Container'
import Col from 'react-bootstrap/Col'
import ScrollAnimation from 'react-animate-on-scroll'
import { BookOpen } from 'react-feather'
import { Link } from 'react-scroll'
import styled from 'styled-components/macro'
import useSwr from 'swr'
import { isWithinInterval, parseISO } from 'date-fns'

import { md } from '../style/media'
import FluidContainer from '../components/Common/FluidContainer'
import Button from '../components/Common/Button'
import backgroundImg from '../images/header-background.jpg'
import Ribbon from 'src/components/Common/Ribbon'
import RibbonMobile from './RibbonMobile'
import fetcher from 'src/utils/fetcher'

const API_URL = process.env.REACT_APP_API_URL

// Inner container
const InnerContainer = styled(Container)`
  position: relative;
  z-index: 2;
  display: flex;
  height: 100%;
  align-items: center;
`

// Presentation col
const StyledCol = styled(Col)`
  margin-top: 150px;

  ${md(`
    margin-top: 200px;
  `)}
`

// Title
const Title = styled.h1`
  font-size: 2rem;
  ${md(`
    font-size: 2.5rem;
  `)}
`

type HomeMessage = {
  Titre: string
  Message: string
  Debut: string
  Fin: string
}

// Jumbotron section
const Jumbotron: React.FC = () => {
  const { data } = useSwr<HomeMessage>(`${API_URL}/message-du-moment`, fetcher, {
    shouldRetryOnError: true,
  })

  const shouldRenderMessage = (begining: string, end: string) => {
    const dateBegining = parseISO(begining)
    const dateEnd = parseISO(end)
    const today = new Date()
    return isWithinInterval(today, { start: dateBegining, end: dateEnd })
  }

  return (
    <>
      <FluidContainer height="90vh" minHeight="650px" image={backgroundImg}>
        <InnerContainer>
          <ScrollAnimation
            animateIn="slideInUp"
            className="row justify-content-between align-items-start h-100"
          >
            <StyledCol xs={12} md={9} xl={6} className="text-white">
              <Title className="mb-md-3 mt-5 mt-sm-0">Restaurant La Duchesse Anne</Title>
              <p className="text-white mb-4">
                La Duchesse Anne est un bar-restaurant situé tout près de la Basilique Notre dame du
                Folgoët en finistère nord. Venez déguster des entrées sous forme de buffet, le plat
                et le dessert servis à table, le tout dans une ambiance conviviale et chaleureuse.
              </p>
              <Link to="restaurant" smooth={true} offset={-70} duration={500}>
                <Button>
                  <BookOpen className="mr-2" />
                  Voir le menu
                </Button>
              </Link>
            </StyledCol>
          </ScrollAnimation>
        </InnerContainer>
        {data && shouldRenderMessage(data.Debut, data.Fin) && (
          <Ribbon title={data.Titre} message={data.Message} />
        )}
      </FluidContainer>
      {data && shouldRenderMessage(data.Debut, data.Fin) && (
        <RibbonMobile title={data.Titre} message={data.Message} />
      )}
    </>
  )
}

export default Jumbotron
