import React from 'react'
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import { Home, Truck } from 'react-feather'
import { Link } from 'react-scroll'
import styled, { withTheme, WithThemeFnInterface } from 'styled-components/macro'
import { DefaultTheme } from 'styled-components'

import Card from '../components/Common/Card'
import Highlight from '../components/Common/Highlight'
import Substar from '../components/Common/Substar'
import homeMadePicto from '../images/picto-fait-maison.png'
import { FORMULA_MIN_PRICE } from 'src/constants/prices'

// Home made picto
const HomeMadePicto = styled.img`
  width: 150px;
  max-width: 100%;
`

// Introduction section
interface IntroductionProps extends WithThemeFnInterface<DefaultTheme> {
  theme: DefaultTheme
}
const Introduction: React.FC<IntroductionProps> = ({ theme }) => {
  return (
    <Container className="py-3 py-sm-5">
      <div className="text-center mb-3 mb-sm-4">
        <h2>A propos de nous</h2>
        <Substar />
      </div>

      <Row className="align-items-center">
        <Col xs={12} md={3} className="order-2 order-md-1 text-center mb-3 mb-sm-4">
          <HomeMadePicto src={homeMadePicto} alt="Fait maison" />
        </Col>
        <Col xs={12} md={9} className="order-1 order-md-2 mb-3 mb-sm-4 text-center text-md-left">
          <p>
            Depuis 2012, avec nos salariés, nous travaillons essentiellement des produits français,
            et le plus souvent possible avec des producteurs locaux. Notre activité est une
            restauration traditionnelle, avec une clientèle d'ouvriers et de particuliers. Notre
            formule avec un buffet d'entrées, plat et buffet desserts plaît beaucoup avec son prix
            attractif.
          </p>
          <p className="mb-0">
            Nous avons un service traiteur pour les repas de groupe servis sur place ou à
            l'extérieur.
            <Highlight> Tous nos plats sont fait maison. </Highlight>
            Notre équipe est constituée de trois cuisiniers et trois serveuses.
          </p>
        </Col>
      </Row>

      <Row>
        <Col xs={12} md={6} className="mb-3 mb-md-0">
          <Link to="restaurant" smooth={true} offset={-70} duration={500}>
            <Card title="Au restaurant" icon={<Home color={theme.primary} size={35} />}>
              <p className="my-0">
                L'équipe vous accueille tous les midis du lundi au vendredi, de 12h à 14h. Une
                formule est disponible pour {FORMULA_MIN_PRICE} € (boisson comprise)
              </p>
            </Card>
          </Link>
        </Col>
        <Col xs={12} md={6} className="mb-3 mb-md-0">
          <Link to="traiteur" smooth={true} offset={-70} duration={500}>
            <Card title="Service traiteur" icon={<Truck color={theme.primary} size={35} />}>
              <p className="my-0">
                La Duchesse Anne vous propose son service traiteur : menu du jour sur commande,
                repas de groupe (famille, séminaire, association, etc.)
              </p>
            </Card>
          </Link>
        </Col>
      </Row>
    </Container>
  )
}

export default withTheme(Introduction)
