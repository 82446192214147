import React from 'react'
import { ArrowRight } from 'react-feather'
import ScrollAnimation from 'react-animate-on-scroll'
import styled, { withTheme, WithThemeFnInterface } from 'styled-components/macro'
import { DefaultTheme } from 'styled-components'

const CardContainer = styled.div`
  cursor: pointer;
`

interface CardProps extends WithThemeFnInterface<DefaultTheme> {
  theme: DefaultTheme
  title: string
  icon: React.ReactNode
}

const Card: React.FC<CardProps> = ({ theme, title, icon, children }) => {
  return (
    <ScrollAnimation animateIn="pulse" initiallyVisible duration={0.5}>
      <CardContainer className="card shadow">
        <div className="card-body px-4">
          {icon && <div className="mb-2">{icon}</div>}
          <h5 className="card-title">{title}</h5>
          <div className="card-text">{children}</div>
          <div className="d-flex justify-content-end">
            <ArrowRight color={theme.primary} />
          </div>
        </div>
      </CardContainer>
    </ScrollAnimation>
  )
}

export default withTheme(Card)
