import React, { useState, useEffect, ButtonHTMLAttributes } from 'react'
import Container from 'react-bootstrap/Container'
import { SRLWrapper } from 'simple-react-lightbox'
import ScrollAnimation from 'react-animate-on-scroll'
import useSwr from 'swr'
import Spinner from 'react-bootstrap/Spinner'
import Alert from 'react-bootstrap/Alert'
import Gallery from 'react-photo-gallery'
import styled from 'styled-components/macro'

import FluidContainer from '../components/Common/FluidContainer'
import Substar from '../components/Common/Substar'
import fetcher from '../utils/fetcher'
import backgroundImg from '../images/nos-assiettes-background.jpg'

import '../style/plates.css'
import { sm } from 'src/style/media'
const API_URL = process.env.REACT_APP_API_URL

// Inner container
const InnerContainer = styled(Container)`
  position: relative;
  z-index: 2;
  height: 100%;
`

// Filter button
interface FilterButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  selected: boolean
}
const FilterButton = styled.button<FilterButtonProps>`
  margin: 5px;
  padding: 5px 20px;
  background-color: ${(props) =>
    props.selected ? props.theme.primary : 'rgba(255, 255, 255, .2)'};
  border: 0;
  border-radius: 1.5rem;
  text-align: center;

  h4 {
    font-size: 12pt;
    color: #ffffff;
    margin: 0;
    font-weight: 600;
  }

  ${sm(`
    margin: 10px;
  `)}
`

// Photo of a plate
type Photo = {
  name: string
  caption: string
  width: number
  height: number
  url: string
}

// Tab containing photos for a category
type PhotosTab = {
  Categorie: string
  Ordre: number
  Photos: Photo[]
}

// Our plates component
const OurPlates: React.FC = () => {
  const [sortedTabs, setSortedTabs] = useState<PhotosTab[]>([])
  const [photos, setPhotos] = useState<Photo[]>([])
  const [selected, setSelected] = useState<string>('')

  // Fetch photos from API
  const { data, error } = useSwr<PhotosTab[]>(`${API_URL}/assiettes`, fetcher)

  // Watch data to select first tab when available
  useEffect(() => {
    if (data && data.length > 0) {
      const sortedPhotos: PhotosTab[] = data
        .slice()
        .sort((photo1, photo2) => photo1.Ordre - photo2.Ordre)
      const firstEntry: PhotosTab = sortedPhotos[0]
      setPhotos(firstEntry.Photos)
      setSelected(firstEntry.Categorie)
      setSortedTabs(sortedPhotos)
    }
  }, [data])

  // Render error, loading or photos tabs
  const renderTabs = () => {
    if (error) {
      return (
        <Alert variant="danger">
          Une erreur est survenue, veuillez nous excuser pour la gêne occasionée.
        </Alert>
      )
    } else if (!data) {
      return (
        <div className="d-flex align-items-center text-white">
          <Spinner animation="border" role="status" className="mr-3">
            <span className="sr-only">Chargement...</span>
          </Spinner>
          <span>Chargement des assiettes...</span>
        </div>
      )
    } else {
      return sortedTabs.map((entry, idx) => (
        <FilterButton
          key={idx}
          selected={selected === entry.Categorie}
          onClick={() => {
            setPhotos(entry.Photos)
            setSelected(entry.Categorie)
          }}
        >
          <h4>{entry.Categorie}</h4>
        </FilterButton>
      ))
    }
  }

  return (
    <FluidContainer image={backgroundImg}>
      <InnerContainer className="py-3 py-sm-5">
        <div className="mb-2 mb-sm-3 text-center text-white">
          <h2>Nos assiettes</h2>
          <Substar />
        </div>

        <div className="mb-3 d-flex flex-row flex-wrap justify-content-center align-items-center">
          {renderTabs()}
        </div>

        <ScrollAnimation animateIn="fadeIn">
          <SRLWrapper>
            <Gallery
              photos={photos.map((photo) => ({
                src: photo.url,
                width: photo.width,
                height: photo.height,
              }))}
              targetRowHeight={200}
            />
          </SRLWrapper>
        </ScrollAnimation>
      </InnerContainer>
    </FluidContainer>
  )
}

export default OurPlates
