import { DefaultTheme } from 'styled-components'

const theme: DefaultTheme = {
  primary: '#F09236',
  hover: '#dd7411',
  text: '#999999',
  darkerText: '#212529',
  christmas: {
    red: '#CC2223',
    green: '#308505',
    darkgreen: '#266803',
  },
}

export default theme
