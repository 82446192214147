import React from 'react'
import ReactDOM from 'react-dom'
import { ThemeProvider } from 'styled-components/macro'

import GlobalStyle from './style/globalStyle'
import App from './App'
import theme from './style/theme'

ReactDOM.render(
  // <React.StrictMode>
  <ThemeProvider theme={theme}>
    <GlobalStyle />
    <App />
  </ThemeProvider>,
  // </React.StrictMode>,
  document.getElementById('root')
)
