import React from 'react'

type FormErrorProps = {
  error?: string
}
const FormError: React.FC<FormErrorProps> = ({ error = 'Ce champs est requis' }) => {
  return (
    <div className="alert alert-danger mt-1" role="alert">
      {error}
    </div>
  )
}

export default FormError
