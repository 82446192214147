import styled from 'styled-components/macro'

interface FluidContainerProps {
  image: string
  height?: string
  minHeight?: string
}

const FluidContainer = styled.div<FluidContainerProps>`
  width: 100vw;
  position: relative;
  z-index: 2;
  background-color: black;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  height: ${(props) => props.height || 'auto'};
  min-height: ${(props) => props.minHeight || 'auto'};

  &:before {
    content: ' ';
    display: block;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
    opacity: 0.4;
    background: url(${(props) => props.image}) no-repeat center center fixed;
    background-size: cover;
  }
`

export default FluidContainer
