import React from 'react'
import styled, { withTheme, WithThemeFnInterface } from 'styled-components/macro'
import { DefaultTheme } from 'styled-components'
import Alert from 'react-bootstrap/Alert'
import Spinner from 'react-bootstrap/Spinner'
import useSwr from 'swr'
import { parseISO, startOfWeek, endOfWeek, format, addDays } from 'date-fns'
import { fr } from 'date-fns/locale'

import DayMenu from './DayMenu'
import fetcher from '../../utils/fetcher'
import { sm } from 'src/style/media'

// Container style
const Container = styled.div`
  display: flex;
  flex-flow: row nowrap;
  overflow-y: scroll;
  justify-content: flex-start;

  ${sm(`
    display: flex;
    flex-flow: row wrap;
    justify-content: center;
  `)}
`

// Title container
const TitleContainer = styled.div`
  text-align: center;
`
const Title = styled.h3`
  color: ${(props) => props.theme.primary};
  font-size: 16pt;
  font-weight: 600;
  text-transform: uppercase;
`

// TYPE menu item
type MenuItem = { Nom: string }

// TYPE restaurant menu for a day
type RestaurantMenu = {
  Date: string
  plats: Array<MenuItem>
  garnitures: Array<MenuItem>
  desserts: Array<MenuItem>
  // closedUntil: string // TODO n'existe pas dans Strapy
}

// WeekMenu component props
interface WeekMenuProps extends WithThemeFnInterface<DefaultTheme> {
  theme: DefaultTheme
}

// WeekMenu component
const WeekMenu: React.FC<WeekMenuProps> = () => {
  const apiUrl = `${process.env.REACT_APP_API_URL}/menu-restaurants`
  let today = new Date()
  // If day is saturday or sunday, show next week menu
  if (parseInt(format(today, 'i')) >= 6) {
    today = addDays(today, 2)
  }
  const weekStart = format(startOfWeek(today, { locale: fr, weekStartsOn: 1 }), 'yyyy-MM-dd')
  const weekEnd = format(endOfWeek(today, { locale: fr, weekStartsOn: 1 }), 'yyyy-MM-dd')
  const weekFilters = `?Date_gte=${weekStart}&Date_lte=${weekEnd}`

  // Fetch menu from API
  const { data, error } = useSwr<RestaurantMenu[]>(apiUrl + weekFilters, fetcher)

  // An error occured while fetching the menu
  if (error) {
    return (
      <Alert variant="danger">
        Une erreur est survenue, veuillez nous excuser pour la gêne occasionée.
      </Alert>
    )
  }

  // Loading
  if (!data) {
    return (
      <div className="d-flex align-items-center">
        <Spinner animation="border" role="status" className="mr-3">
          <span className="sr-only">Chargement...</span>
        </Spinner>
        <span>Chargement du menu...</span>
      </div>
    )
  }

  // Menu is incomplete or missing
  if (data?.length !== 5) {
    return <Alert variant="warning">Le menu de la semaine n'a pas encore été mis en ligne.</Alert>
  }

  return (
    <>
      <TitleContainer>
        <Title>Menu de la semaine</Title>
      </TitleContainer>
      <Container>
        {data.map((menu, idx) => (
          <DayMenu
            key={idx}
            idx={idx}
            date={parseISO(menu.Date)}
            meals={menu.plats?.map((plat) => plat.Nom) || []}
            garnish={menu.garnitures?.map((garniture) => garniture.Nom) || []}
            deserts={menu.desserts?.map((dessert) => dessert.Nom) || []}
            closedUntil={null} // TODO n'existe pas dans strapy
          />
        ))}
      </Container>
    </>
  )
}

export default withTheme(WeekMenu)
