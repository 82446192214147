import React from 'react'
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import { Facebook, MapPin } from 'react-feather'
import styled from 'styled-components/macro'

import Button from '../components/Common/Button'
import logo from '../images/logo.png'
import { lg, md } from 'src/style/media'

// Fluid container
const StyledContainer = styled.div`
  width: 100%;
  background-color: #000000;
  color: #ffffff;
  font-size: 10pt;

  p {
    color: #ffffff;
  }

  ${lg(`
    font-size: 12pt;
  `)}
`

// Section title
const Title = styled.h4`
  font-size: 1.25rem;
  text-transform: uppercase;

  ${lg(`
    font-size: 1.5rem;
  `)}
`

// Brand logo
const BrandLogo = styled.img`
  width: 150px;

  ${md(`
    width: 75%;
  `)}
`

// Footer section
const Footer: React.FC = () => {
  return (
    <StyledContainer>
      <Container className="pt-3 pt-md-5 pb-2">
        <Row>
          <Col xs={12} md={3} className="mb-3 mb-md-0 d-flex flex-column align-items-center">
            <BrandLogo src={logo} alt="Logo Restaurant Duchesse Anne" />
            <div className="d-flex flex-row align-items-center">
              <MapPin color="#ffffff" className="mr-2" />
              <div className="flex-1">
                <p className="my-0">6 place de l'église</p>
                <p className="my-0">29260 Le Folgoët</p>
              </div>
            </div>
          </Col>
          <Col
            xs={6}
            sm={4}
            md={3}
            className="mb-3 mb-md-0 d-flex flex-column text-center text-sm-left"
          >
            <Title>Restaurant</Title>
            <p className="my-0">11h45 - 14h</p>
            <p className="my-0">Lundi - Vendredi</p>
          </Col>
          <Col
            xs={6}
            sm={4}
            md={3}
            className="mb-3 mb-md-0 d-flex flex-column text-center text-sm-left"
          >
            <Title>Traiteur</Title>
            <p className="my-0">Appeler pour commander: 02.98.21.18.39</p>
            <p className="my-0">Toute la semaine</p>
          </Col>
          <Col xs={12} sm={4} md={3} className="text-center">
            <a
              href="https://www.facebook.com/Restaurant-La-Duchesse-Anne-103904881208286"
              target="blank"
            >
              <Button>
                <Facebook className="mr-2" />
                Facebook
              </Button>
            </a>
          </Col>
        </Row>

        <p className="text-center mt-5">
          <small>Copyright © Yohann Legrand 2020</small>
        </p>
      </Container>
    </StyledContainer>
  )
}

export default Footer
