import React, { useState } from 'react'
import { Camera, Info } from 'react-feather'
import styled from 'styled-components/macro'
import { md, lg } from 'src/style/media'
import Tooltip from 'react-bootstrap/Tooltip'
import { OverlayTrigger } from 'react-bootstrap'

const StyledHeaderSection = styled.div`
  display: flex;
  flex-flow: row no-wrap;
  justify-content: space-between;
  align-items: center;
  text-transform: uppercase;
  padding: 1.5rem 0;
  border-bottom: 1px solid #ffffff;

  ${lg(`
    padding: 2rem 0;
  `)}
`

// Header title
const StyledHeaderTitle = styled.h3`
  margin: 0;
  font-size: 1.5rem;

  ${md(`
    font-size: 1.25rem;
  `)}
  ${lg(`
    font-size: 1.5rem;
  `)}
`

// Header icons
const StyledIcons = styled.div`
  white-space: nowrap;
`

// Prop Types
type Props = {
  index: number
  desc?: string
  photos: Array<MenuPhoto>
  openPreview: (photos: Array<MenuPhoto>) => void
}

// Header section component
const MenuSectionHeader: React.FC<Props> = ({ index, desc, photos, openPreview, children }) => {
  const [showTooltip, setShowTooltip] = useState(false)

  return (
    <StyledHeaderSection className="text-white mb-3">
      <StyledHeaderTitle>{children}</StyledHeaderTitle>
      <StyledIcons>
        {photos.length > 0 && (
          <Camera
            data-for="main"
            data-tip="Regarder des photos d'assiettes"
            style={{ cursor: 'pointer' }}
            onClick={() => openPreview(photos)}
          />
        )}
        {desc && (
          <OverlayTrigger
            trigger={['hover', 'focus', 'click']}
            rootClose
            placement="top"
            // show={showTooltip}
            overlay={
              <Tooltip id={`tooltip-${index}`} placement="top">
                {desc.split('<br/>').join('\n\n')}
              </Tooltip>
            }
          >
            <Info
              style={{ cursor: 'pointer' }}
              className="ml-2"
              onClick={() => setShowTooltip(!showTooltip)}
            />
          </OverlayTrigger>
        )}
      </StyledIcons>
    </StyledHeaderSection>
  )
}

export default MenuSectionHeader
