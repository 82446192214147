import React from 'react'
import Container from 'react-bootstrap/Container'
import { PhoneCall } from 'react-feather'
import styled from 'styled-components/macro'

import TraiteurMenu from '../components/Traiteur/Menu'
import FluidContainer from '../components/Common/FluidContainer'
import Button from '../components/Common/Button'
import Substar from '../components/Common/Substar'

import background from '../images/menu-traiteur-background.jpg'

// Inner container
const InnerContainer = styled(Container)`
  position: relative;
  z-index: 2;
  height: 100%;
`

const Traiteur: React.FC = () => {
  return (
    <FluidContainer image={background} id="traiteur">
      <InnerContainer className="py-3 py-sm-5">
        <div className="text-center">
          <div className="mb-3 mb-sm-4 text-white">
            <h2>Service Traiteur</h2>
            <Substar />
          </div>
          <p className="mb-4 text-white">
            N'attendez plus dans les magasins... Commandez vos plats préparés par téléphone ! <br />
            Choisissez, la veille ou le matin même avant 12h, un ou plusieurs plats d'un menu de la
            semaine!
          </p>
          <a href="tel:+33298211839" className="mr-1 mr-sm-3">
            <Button shadow>
              <PhoneCall className="mr-2" />
              02.98.21.18.39
            </Button>
          </a>
        </div>

        <TraiteurMenu />
      </InnerContainer>
    </FluidContainer>
  )
}

export default Traiteur
