import React from 'react'
import capitalize from 'capitalize-first-letter'
import ScrollAnimation from 'react-animate-on-scroll'
import { fr } from 'date-fns/locale'
import { format } from 'date-fns'
import styled from 'styled-components/macro'
import { md, lg } from '../../style/media'

import Highlight from '../Common/Highlight'

// Container
type StyledDayMenuProps = {
  idx: number
}
const StyledDayMenu = styled(ScrollAnimation)<StyledDayMenuProps>`
  flex-grow: 0;
  flex-shrink: 0;
  flex-basis: calc(80% - 30px);
  margin: 15px;
  padding: 10px;
  border: 1px solid rgba(0, 0, 0, 0.125);
  border-radius: 15px;
  transition: all 0.2s ease;

  &:hover {
    transform: rotate(${(props) => (props.idx % 2 ? '2deg' : '-2deg')}) !important;
  }

  ${md(`
    flex-basis: calc(50% - 30px);
  `)}
  ${lg(`
    flex-basis: calc(30% - 30px);
  `)}
`

// Menu title
const TitleContainer = styled.button`
  padding: 5px 20px;
  background-color: ${(props) => props.theme.primary};
  border: 0;
  border-radius: 1.5rem;
  text-align: center;
`
const Title = styled.h4`
  font-size: 12pt;
  color: #ffffff;
  margin: 0;
  font-weight: 600;
  text-transform: uppercase;
`

// Menu section
const Section = styled.div`
  padding: 10px 0;
  border-bottom: 1px solid #bbb;

  &:last-child {
    border-bottom: 0;
  }
`

// Meal list
const MenuList = styled.ul`
  list-style: none;
  font-size: 10pt;
  padding: 0;
  margin: 0;

  ${lg(`
    font-size: 12pt;
  `)}
`

// Garnish item
const Garnish = styled.li`
  color: #ddd;
`

// Section title ("Plat principal" / "Garniture")
// eslint-disable-next-line @typescript-eslint/no-explicit-any
const StyledHighlight = styled(Highlight as any)`
  font-family: BillySignature;
  font-size: 14pt;

  ${lg(`
    font-size: 16pt;
  `)}
`

// Restaurant is closed this day, until x
const ClosedUntil = styled.div`
  display: block;
  margin: 50px 10px;
  border: 5px solid ${(props) => props.theme.primary};
  color: ${(props) => props.theme.primary};
  text-transform: uppercase;
  text-align: center;
  padding: 10px;
  /* transform: rotateX(30deg) !important; */
`
const Closed = styled.div`
  font-size: 20pt;
  font-weight: bold;
`
const Until = styled.span`
  font-size: 14pt;
`

// Day menu component props
type DayMenuProps = {
  idx: number
  date: Date
  meals: Array<string>
  garnish: Array<string>
  deserts?: Array<string>
  closedUntil: Date | null
}

// Day menu component
const DayMenu: React.FC<DayMenuProps> = ({
  idx,
  date,
  meals,
  garnish,
  deserts = [],
  closedUntil,
}) => {
  const renderSections = () => (
    <>
      <Section>
        <div>
          <StyledHighlight>Plat principal</StyledHighlight>
        </div>
        <MenuList>
          {meals.map((meal, idx) => (
            <li key={idx}>{capitalize(meal)}</li>
          ))}
        </MenuList>
      </Section>
      <Section>
        <div>
          <StyledHighlight>Garniture</StyledHighlight>
        </div>
        <MenuList>
          {garnish.map((item, idx) => (
            <Garnish key={idx}>{capitalize(item)}</Garnish>
          ))}
        </MenuList>
      </Section>
      {deserts.length > 0 && (
        <Section>
          <div>
            <StyledHighlight>Dessert</StyledHighlight>
          </div>
          <MenuList>
            {deserts.map((item, idx) => (
              <li key={idx}>{capitalize(item)}</li>
            ))}
          </MenuList>
        </Section>
      )}
    </>
  )

  const renderClosedUntil = (date: string) => (
    <ClosedUntil>
      <Closed>Fermé</Closed>
      <Until>Jusqu'au {date}</Until>
    </ClosedUntil>
  )

  return (
    <StyledDayMenu
      animateIn="flipInY"
      duration={0.5}
      offset={100}
      className="shadow bg-dark text-white"
      idx={idx}
    >
      <div className="text-center">
        <TitleContainer>
          <Title>{format(date, 'EEEE dd', { locale: fr })}</Title>
        </TitleContainer>
      </div>
      {closedUntil
        ? renderClosedUntil(format(closedUntil, 'dd/MM/yyyy', { locale: fr }))
        : renderSections()}
    </StyledDayMenu>
  )
}

export default DayMenu
