import React from 'react'
import styled from 'styled-components/macro'
import ScrollAnimation from 'react-animate-on-scroll'
import ReactMarkdown from 'react-markdown'

import { lg } from 'src/style/media'
import { Info } from 'react-feather'

// Ribbon
const StyledRibbon = styled(ScrollAnimation)`
  z-index: 5;
  position: absolute;
  bottom: 30px;
  right: 0;
  padding: 20px;
  background-color: ${(props) => props.theme.primary};
  border-radius: 15px 0 0 15px;
  max-width: 85%;
  flex-direction: row;
  align-items: center;
`

// Ribbon title
const Title = styled.h2`
  color: #ffffff;
  font-family: BillySignature;
  font-size: 1.5rem;

  ${lg(`
    font-size: 2rem;
  `)}
`

// Ribbon message body
const Message = styled.div`
  p {
    color: #ffffff !important;
  }
`

type Props = {
  title: string
  message: string
}

// Chrtismas ribbon component
const Ribbon: React.FC<Props> = ({ title, message }) => {
  return (
    <StyledRibbon
      animateIn="slideInRight"
      animateOut="slideOutRight"
      duration={0.5}
      offset={200}
      className="d-none d-sm-flex"
    >
      <Info size={50} color="white" />
      <div className="ml-3">
        <Title>{title}</Title>
        <Message>
          <ReactMarkdown source={message} />
        </Message>
      </div>
    </StyledRibbon>
  )
}

export default Ribbon
