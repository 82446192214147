import React from 'react'
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import styled from 'styled-components/macro'

import WeekMenu from '../components/Restaurant/WeekMenu'
import Substar from '../components/Common/Substar'
import Thumbnail from '../components/Common/Thumbnail'
import restau from '../images/resto.jpg'
import { md, lg } from 'src/style/media'
import { FORMULA_MAX_PRICE, FORMULA_MIN_PRICE } from 'src/constants/prices'

// Paragraph in intro text
const Paragraph = styled.p`
  margin-bottom: 1rem;
  ${md(`
    margin-bottom: 0;
  `)}
  ${lg(`
    margin-bottom: 1rem;
  `)}
`

// Formulas list
const Formulas = styled.ul`
  padding: 0 30px;
  color: ${(props) => props.theme.text};

  ${md(`
    padding: 0 15px;
  `)}
  ${lg(`
    padding: 0 30px;
  `)}
`

// Restaurant section
const Restaurant: React.FC = () => {
  return (
    <Container className="py-3 py-sm-5" id="restaurant">
      <Row className="justify-content-between align-items-center mb-4">
        <Col xs={12} md={4} className="order-2 order-md-1 mt-2 mt-md-0">
          <Thumbnail src={restau} alt="Salle du restaurant" className="shadow-lg" />
        </Col>
        <Col xs={12} md={8} className="order-1 order-md-2">
          <div className="mb-3 mb-sm-4 text-center">
            <h2>Au Restaurant</h2>
            <Substar />
          </div>
          <Paragraph>
            L'équipe du restaurant vous accueille tous les midis du lundi au vendredi de 11h45 à
            14h.
          </Paragraph>
          <Paragraph>Deux formules sont proposées (boissons et café compris):</Paragraph>
          <Formulas>
            <li>
              <b>Formule à {FORMULA_MIN_PRICE} € : </b>
              buffet de salades composées et charcuteries + dessert <u>OU</u> plat du jour au choix
              avec sa garniture + dessert
            </li>
            <li>
              <b>Formule à {FORMULA_MAX_PRICE} € : </b>
              buffet de salades composées et charcuteries, plat du jour au choix avec sa garniture,
              dessert
            </li>
          </Formulas>
        </Col>
      </Row>

      <WeekMenu />
    </Container>
  )
}

export default Restaurant
