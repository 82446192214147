import React, { useEffect, useState } from 'react'
import { Navbar as BootstrapNavbar, Nav, Container } from 'react-bootstrap'
import cn from 'classnames'
import { Link, animateScroll as scroll } from 'react-scroll'
import styled from 'styled-components/macro'

import logo from '../images/logo.png'
import { md } from '../style/media'

// Navbar
const StyledNavbar = styled(BootstrapNavbar)`
  background-color: rgba(0, 0, 0, 0.8);
  &.reduced {
    height: 60px;

    .navbar-collapse.collapse.show,
    .navbar-collapse.collapsing {
      background-color: #ffffff;
      padding-top: 30px;
    }
  }

  ${md(`
    background-color: rgba(0, 0, 0, 0.4);
    transition: all 0.7s ease;

    &.reduced {
      height: 80px;
    }
  `)}
`

// Brand logo
interface BrandLogoProps {
  fullHeader: boolean
}
const BrandLogo = styled.img<BrandLogoProps>`
  z-index: 10;
  cursor: pointer;
  height: ${(props) => (props.fullHeader ? '100px' : '80px')};
  ${(props) =>
    !props.fullHeader &&
    `
    position: absolute;
    top: 2px;
  `}

  ${(props) =>
    md(`
    height: ${props.fullHeader ? '120px' : '100px'};
  `)}
`

// Navbar link
const collapsedColor = 'rgb(37, 37, 37)'
const NavLink = styled(Link)`
  cursor: pointer;
  text-transform: uppercase;
  color: ${collapsedColor} !important;
  font-weight: 600;
  font-size: 9pt;
  margin-left: 1rem;
  white-space: nowrap;
  padding: 8px 1px !important;
  transition: color 0.2s ease, border 0.2s ease;
  &.fullheader {
    color: #ffffff !important;
  }
  &.scrollActive {
    border-bottom: 2px solid ${collapsedColor};
    &.fullheader {
      border-bottom: 2px solid #ffffff;
    }
  }
  &:hover {
    color: ${(props) => props.theme.primary} !important;
    &.scrollActive {
      border-color: ${(props) => props.theme.primary};
    }
  }

  ${md(`
    font-size: 10pt;
    margin-left: 2.5rem;
  `)}
`

// Navbar component
export type NavLink = {
  name: string
  to: string
}
interface NavbarProps {
  links: NavLink[]
}
const Navbar: React.FC<NavbarProps> = ({ links }) => {
  const [fullHeader, setFullHeader] = useState(true)

  // Scroll listener
  useEffect(() => {
    const listener = () => {
      setFullHeader(window.scrollY < 400)
    }
    window.addEventListener('scroll', listener)
    return () => {
      window.removeEventListener('scroll', listener)
    }
  })

  return (
    <StyledNavbar
      expand={'sm'}
      fixed={'top'}
      collapseOnSelect
      className={cn({
        reduced: !fullHeader,
        'bg-white': !fullHeader,
        'navbar-dark': fullHeader,
      })}
    >
      <Container>
        <BootstrapNavbar.Brand>
          <BrandLogo
            src={logo}
            alt="Restaurant La Duchesse Anne logo"
            fullHeader={fullHeader}
            className={cn('navbar-brand', { 'py-0': !fullHeader })}
            onClick={() => scroll.scrollToTop()}
          />
          <span className="sr-only">Restaurant La Duchesse Anne</span>
        </BootstrapNavbar.Brand>

        <BootstrapNavbar.Toggle aria-controls="responsive-navbar-nav" />

        <BootstrapNavbar.Collapse id="responsive-navbar-nav">
          <Nav className="ml-auto">
            {links.map((link, idx) => (
              <Nav.Item key={idx}>
                <Nav.Link
                  as={NavLink}
                  eventKey={idx}
                  activeClass="scrollActive"
                  to={link.to}
                  spy={true}
                  smooth={true}
                  offset={-70}
                  duration={500}
                  className={cn('nav-link', { fullheader: fullHeader })}
                >
                  {link.name}
                </Nav.Link>
              </Nav.Item>
            ))}
          </Nav>
        </BootstrapNavbar.Collapse>
      </Container>
    </StyledNavbar>
  )
}

export default Navbar
