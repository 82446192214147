import React from 'react'
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import styled from 'styled-components/macro'

import ContactForm from '../components/Contact/ContactForm'
import ContactInfos from '../components/Contact/ContactInfos'
import contactBanner from '../images/banner-contact.jpg'
import oldWine from '../images/oldwine.png'
import { lg, md } from 'src/style/media'

// Banner with the picture
const Banner = styled.div`
  width: 100%;
  height: 150px;
  margin-left: auto;
  margin-right: auto;
  background: url(${contactBanner}) no-repeat center center;
  background-size: cover;
  position: relative;

  ${md(`
    height: 200px;
  `)}
  ${lg(`
    height: 300px;
  `)}
`

// Title on the banner
const BannerTitle = styled.div`
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 50px;
  color: #ffffff;
  z-index: 2;
  background: rgba(0, 0, 0, 0.5);
  text-align: center;
  // text-transform: uppercase;
`

// Contact section
const Contact: React.FC = () => {
  return (
    <Container className="py-3 py-sm-5" id="contact">
      <Banner className="mb-3 mb-sm-4">
        <BannerTitle>
          <h2>Rentrons en contact</h2>
        </BannerTitle>
      </Banner>
      <Row className="align-items-center my-xs-3 my-lg-5">
        <Col xs={4} className="d-none d-sm-block d-md-none">
          <img src={oldWine} alt="Old wine" className="w-100" />
        </Col>
        <Col xs={12} sm={8} md={12}>
          <Row>
            <Col xs={12} md={4} className="mb-3 mb-md-0 text-center">
              <ContactInfos />
            </Col>
            <Col xs={12} md={8}>
              <p className="mb-0 text-center text-sm-left">
                Si vous avez une question ou un commentaire, n'hésitez pas à nous joindre par le
                moyen qui vous convient le mieux. Vous pouvez utiliser ce formulaire pour
                transmettre un message, il sera directement adressé à l'équipe du restaurant
                Duchesse Anne qui vous répondra dans les plus brefs délais.
              </p>
            </Col>
          </Row>
        </Col>
      </Row>

      <div className="row align-items-start mt-4">
        <div className="d-none d-md-block col-md-4">
          <img src={oldWine} alt="Old wine" className="w-100" />
        </div>

        <div className="col-12 col-md-8">
          <ContactForm />
        </div>
      </div>
    </Container>
  )
}

export default Contact
