import React from 'react'
import styled from 'styled-components/macro'

const StyledSubstar = styled.div`
  text-align: center;
  font-size: 17px;
  font-family: Serif;
  opacity: 0.8;
`

const Substar: React.FC = () => <StyledSubstar>✻</StyledSubstar>

export default Substar
