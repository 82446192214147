import React from 'react'
import Container from 'react-bootstrap/Container'
import ReactMarkdown from 'react-markdown'
import styled from 'styled-components/macro'
import Substar from '../components/Common/Substar'

// Container
const StyledContainer = styled(Container)`
  background-color: ${(props) => props.theme.primary};
  color: #ffffff;
  text-align: center;
`

// Title
const Title = styled.h2`
  font-family: BillySignature;
  font-size: 1.5rem;
`

// Message body
const Message = styled.div`
  p {
    color: #ffffff !important;
  }
`

type Props = {
  title: string
  message: string
}

// Christmas menu promotion for mobile
const RibbonMobile: React.FC<Props> = ({ title, message }) => {
  return (
    <StyledContainer fluid className="d-block d-sm-none py-3">
      <div className="mb-3">
        <Title>{title}</Title>
        <Substar />
      </div>
      <Message>
        <ReactMarkdown source={message} />
      </Message>
    </StyledContainer>
  )
}

export default RibbonMobile
