import React from 'react'
import { MapPin, Phone } from 'react-feather'
import styled, { withTheme, WithThemeFnInterface } from 'styled-components/macro'
import { DefaultTheme } from 'styled-components'

import Button from '../Common/Button'

// Phone numbers container
const PhoneNumbers = styled.div`
  display: flex;
  justify-content: space-around;
  flex-flow: row wrap;
`

interface ContactInfosProps extends WithThemeFnInterface<DefaultTheme> {
  theme: DefaultTheme
}

const ContactInfos: React.FC<ContactInfosProps> = ({ theme }) => {
  return (
    <>
      <p className="darker">
        <MapPin color={theme.primary} /> 6 place de l'église, 29260 Le Folgoët
      </p>
      <PhoneNumbers>
        <a href="tel:+33298211839" className="mb-2 mx-1">
          <Button shadow>
            <Phone className="mr-2" />
            02.98.21.18.39
          </Button>
        </a>
        {/* <a href="tel:+33602510332" className="mx-1">
          <Button shadow>
            <Smartphone className="mr-2" />
            06.02.51.03.32
          </Button>
        </a> */}
      </PhoneNumbers>
    </>
  )
}

export default withTheme(ContactInfos)
