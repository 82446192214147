import React, { useState } from 'react'
import Button from 'react-bootstrap/Button'
import Alert from 'react-bootstrap/Alert'
import { useForm } from 'react-hook-form'
import { Send } from 'react-feather'
import cn from 'classnames'
import validator from 'validator'
import emailjs from 'emailjs-com'
import styled from 'styled-components/macro'

import StyledButton from '../Common/Button'
import FormError from './FormError'

// Label style
const Label = styled.label`
  font-weight: 550;
`

// Contact form component
const ContactForm: React.FC = () => {
  const { register, handleSubmit, errors, reset } = useForm()
  const [isLoading, setLoading] = useState(false)
  const [message, setMessage] = useState('')
  const [error, setError] = useState('')

  type FormData = {
    name: string
    subject: string
    email: string
    message: string
  }

  // Submit form
  const onSubmit = async (data: FormData) => {
    setLoading(true)

    // Retrieve env variables
    const {
      REACT_APP_EMAILJS_SERVICE_ID,
      REACT_APP_EMAILJS_TEMPLATE_ID,
      REACT_APP_EMAILJS_USER_ID,
    } = process.env

    // Prepare template variables
    const variables = {
      name: data.name,
      subject: data.subject,
      email: data.email,
      message_html: data.message.replace(/(?:\r\n|\r|\n)/g, '<br>'),
    }

    try {
      // Verify env vars
      if (
        !REACT_APP_EMAILJS_SERVICE_ID ||
        !REACT_APP_EMAILJS_TEMPLATE_ID ||
        !REACT_APP_EMAILJS_USER_ID
      ) {
        throw new Error('Env vars missing')
      }

      // Send form data to emailjs
      await emailjs.send(
        REACT_APP_EMAILJS_SERVICE_ID,
        REACT_APP_EMAILJS_TEMPLATE_ID,
        variables,
        REACT_APP_EMAILJS_USER_ID
      )
      setMessage("Votre message a bien été adressé à l'équipe de la Duchesse Anne.")
    } catch (e) {
      // error sending mail
      console.error(e)
      setError(`Une erreur est survenue lors de l'envoi de votre message, veuillez
        réessayer ultérieurement. Toutes nos excuses pour la gêne occasionnée.`)
    } finally {
      // reset component state
      setLoading(false)
      reset()
    }
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className="form-group">
        <Label htmlFor="name">Votre nom</Label>
        <input
          type="text"
          className={cn('form-control', {
            'is-invalid': errors.name,
          })}
          name="name"
          ref={register({ required: true })}
        />
        {errors.name && <FormError />}
      </div>

      <div className="form-group">
        <Label htmlFor="email">Votre adresse mail</Label>
        <input
          type="email"
          formNoValidate
          className={cn('form-control', {
            'is-invalid': errors.email,
          })}
          name="email"
          ref={register({
            required: true,
            validate: (value) => validator.isEmail(value),
          })}
        />
        {errors.email && <FormError error="Veuillez indiquer une adresse mail valide" />}
      </div>

      <div className="form-group">
        <Label htmlFor="name">Sujet</Label>
        <input
          type="text"
          className={cn('form-control', {
            'is-invalid': errors.subject,
          })}
          name="subject"
          ref={register({ required: true })}
        />
        {errors.subject && <FormError />}
      </div>

      <div className="form-group">
        <Label htmlFor="message">Message</Label>
        <textarea
          rows={7}
          // multiline="true"
          className={cn('form-control', {
            'is-invalid': errors.message,
          })}
          name="message"
          ref={register({ required: true })}
        />
        {errors.message && <FormError />}
      </div>

      <div className="form-group">
        <Button as={StyledButton} disabled={isLoading} shadow type="submit">
          <Send className="mr-2" />
          {isLoading ? 'Envoi du mail...' : 'Envoyer'}
        </Button>
      </div>

      <Alert
        variant="success"
        dismissible
        show={message.trim().length > 0}
        onClose={() => setMessage('')}
      >
        {message}
      </Alert>
      <Alert
        variant="danger"
        dismissible
        show={error.trim().length > 0}
        onClose={() => setError('')}
      >
        {error}
      </Alert>
    </form>
  )
}

export default ContactForm
