import React from 'react'
import styled from 'styled-components/macro'
import { md, lg } from '../../style/media'
import MenuSectionHeader from './MenuSectionHeader'

// Item title
const Title = styled.div`
  font-weight: bold;
`

// Dots
const Dots = styled.div`
  flex: 1;
  border-bottom: 1px dotted #ffffff;
  margin: 0 5px;
  margin-bottom: 7px;
`

// Item price
const Price = styled.div`
  font-weight: bold;
  color: ${(props) => props.theme.primary};
`

// Item description
const Desc = styled.p`
  color: #bbb;
  font-size: 12pt;

  ${md(`
    font-size: 10pt;
  `)}
  ${lg(`
    font-size: 12pt;
  `)}
`

// Props
type Props = {
  index: number
  title: string
  desc?: string
  photos: Array<MenuPhoto>
  items: Array<{
    title: string
    subtitle: string
    price: number
  }>
  openPreview: (photos: Array<MenuPhoto>) => void
}

// Component to render a section of the traiteur menu
const MenuSection: React.FC<Props> = ({ index, title, desc, photos, items, openPreview }) => {
  return (
    <>
      <MenuSectionHeader index={index} desc={desc} photos={photos} openPreview={openPreview}>
        {title}
      </MenuSectionHeader>
      {items.map((item, idx) => (
        <div className="text-white px-0 py-1" key={idx}>
          <div className="d-flex align-items-end justify-content-center">
            <Title>{item.title}</Title>
            <Dots />
            <Price>{item.price}€</Price>
          </div>
          <Desc>{item.subtitle}</Desc>
        </div>
      ))}
    </>
  )
}

export default MenuSection
