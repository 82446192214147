import React from 'react'
import cn from 'classnames'
import styled from 'styled-components/macro'

const StyledButton = styled.button`
  background-color: ${(props) => props.theme.primary} !important;
  border-color: ${(props) => props.theme.primary} !important;
  font-weight: 600 !important;

  &:hover {
    background-color: ${(props) => props.theme.hover} !important;
    border-color: ${(props) => props.theme.hover} !important;
  }
`

interface ButtonProps {
  shadow?: boolean
}
const Button: React.FC<ButtonProps> = ({ children, shadow, ...props }) => (
  <StyledButton className={cn('btn text-white', { shadow: shadow })} {...props}>
    {children}
  </StyledButton>
)

export default Button
