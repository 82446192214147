import React, { useEffect, useState } from 'react'
import useSwr from 'swr'
import { SRLWrapper, useLightbox } from 'simple-react-lightbox'
import Alert from 'react-bootstrap/Alert'
import Spinner from 'react-bootstrap/Spinner'
import styled from 'styled-components/macro'

import MenuSection from './MenuSection'
import fetcher from '../../utils/fetcher'
import { md } from 'src/style/media'

// Mansory layout
const Masonry = styled.div`
  display: flex;
  flex-flow: column wrap;

  ${md(`
    max-height: 1300px;
  `)}
`

// Menu section container
const Section = styled.div`
  flex-basis: 100%;
  // min-width: 100%;
  // max-width: calc(100% - 30px);

  ${md(`
    flex-basis: calc(50% - 30px);
    min-width: calc(50% - 30px);
    max-width: calc(50% - 30px);
    margin: 0 15px;
  `)}
`

// Items of a menu section from Strapi
type MenuItem = {
  Titre: string
  Description: string
  Prix: number
}

// Menu section from Strapi
type MenuSection = {
  Ordre: number
  Titre: string
  Description?: string
  Photos: Array<MenuPhoto>
  Items: Array<MenuItem>
}

// Traiteur menu component
const Menu: React.FC = () => {
  const [photos, setPhotos] = useState<MenuPhoto[]>([])
  const [sortedMenuSections, setSortedMenuSections] = useState<MenuSection[]>([])
  const { openLightbox } = useLightbox()

  // Fetch menu from API
  const apiUrl = process.env.REACT_APP_API_URL
  const { data, error } = useSwr<MenuSection[]>(`${apiUrl}/section-menu-traiteurs`, fetcher)

  // Lightbox callbacks
  const callbacks = {
    onLightboxClosed: () => {
      // Empty photos in state
      setPhotos([])
    },
  }

  // Change photos in preview zone and open lightbox
  const openPreview = (photos: MenuPhoto[]) => {
    setPhotos(photos)
  }

  // use effect hook to open lightbox when photos change
  useEffect(() => {
    if (photos.length)
      setTimeout(() => {
        openLightbox()
      }, 500)
  }, [photos])

  // Sort menu sections
  useEffect(() => {
    if (data && data.length > 0) {
      const sortedSections = data.sort((section1, section2) => section1.Ordre - section2.Ordre)
      setSortedMenuSections(sortedSections)
    }
  }, [data])

  // An error occured while fetching the menu
  if (error) {
    return (
      <Alert variant="danger" className="mt-3">
        Une erreur est survenue, veuillez nous excuser pour la gêne occasionée.
      </Alert>
    )
  }

  // Loading
  if (!data) {
    return (
      <div className="d-flex align-items-center">
        <Spinner animation="border" role="status" className="mr-3">
          <span className="sr-only">Chargement...</span>
        </Spinner>
        <span>Chargement du menu...</span>
      </div>
    )
  }

  // Menu is incomplete or missing
  if (data.length === 0) {
    return <Alert variant="warning">Le menu traiteur n'a pas encore été mis en ligne.</Alert>
  }

  // Render component
  return (
    <Masonry>
      {sortedMenuSections.map((section, idx) => (
        <Section key={idx}>
          <MenuSection
            index={idx}
            title={section.Titre}
            desc={section.Description?.replaceAll('\n', '<br/>')}
            photos={section.Photos}
            items={section.Items.map((item) => ({
              title: item.Titre,
              subtitle: item.Description,
              price: item.Prix,
            }))}
            openPreview={openPreview}
          />
        </Section>
      ))}

      <SRLWrapper callbacks={callbacks}>
        <div className="d-none">
          {photos.map((photo, idx) => (
            <img src={photo.url} alt={photo.caption} key={idx} />
          ))}
        </div>
      </SRLWrapper>
    </Masonry>
  )
}

export default Menu
