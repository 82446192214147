import React from 'react'
import SimpleReactLightbox from 'simple-react-lightbox'

import 'bootstrap/dist/css/bootstrap.min.css'
import './style/fonts.css'

import Navbar, { NavLink } from './sections/Navbar'
import Jumbotron from './sections/Jumbotron'
import Introduction from './sections/Introduction'
import OurPlates from './sections/OurPlates'
import Restaurant from './sections/Restaurant'
import Traiteur from './sections/Traiteur'
import Contact from './sections/Contact'
import MapSection from './sections/Map'
import Footer from './sections/Footer'

const links: NavLink[] = [
  { name: 'Accueil', to: 'top' },
  { name: 'Au Restaurant', to: 'restaurant' },
  { name: 'Service Traiteur', to: 'traiteur' },
  { name: 'Contact', to: 'contact' },
]

const App: React.FC = () => {
  return (
    <div>
      <Navbar links={links} />
      <div id="top">
        <Jumbotron />
        <Introduction />
        <SimpleReactLightbox>
          <OurPlates />
        </SimpleReactLightbox>
      </div>
      <Restaurant />
      <SimpleReactLightbox>
        <Traiteur />
      </SimpleReactLightbox>
      <Contact />
      <MapSection />
      <Footer />
    </div>
  )
}

export default App
